import React from "react"
import "./philosophy_card.css"

const PhilosophyCard = ({ icon, title, description }) => {
  return (
    <div className="philosophy-card ">
      <div className="content">
        <div className="icon-wrapper">
          <img src={icon} alt="icon" />
        </div>
        <div className="title">{title}</div>
        <article className="description">{description}</article>
      </div>
    </div>
  )
}

export default PhilosophyCard
