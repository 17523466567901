import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import PhilosophyCard from "./PhilosophyCard"

const MobileDeck = styled.div`
  position: absolute;
  box-sizing: content-box;
  display: flex;
  height: auto;
  max-height: 300px;
  transition: transform 500ms cubic-bezier(0.24, 0.15, 0.53, 0.99);
  transform: translateX(${props => props.transform}px);
`

const DesktopDeck = styled.div`
  box-sizing: content-box;
  display: flex;
  max-height: 300px;
  flex-direction: column;
  max-height: initial;
  height: 50vh;
  right: 0;
  margin-top: 40px;
  width: 100%;
`
const Tier = styled.div`
  display: flex;
  margin-right: 12px;
`

const PhilosophyDeck = ({ handlers, isMobile, displacement }) => {
  const data = useStaticQuery(graphql`
    query PhilosophyePageQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/PhilosophyPage/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              abstract
            }
            rawMarkdownBody
          }
        }
      }
      allFile(
        filter: {
          sourceInstanceName: { eq: "PhilosophyPage" }
          internal: { mediaType: { eq: "image/svg+xml" } }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            publicURL
          }
        }
        distinct(field: name)
      }
    }
  `)

  const content = data.allMarkdownRemark.edges
  const icons = data.allFile.edges
  const distinct = data.allFile.distinct

  return isMobile ? (
    <MobileDeck {...handlers} transform={displacement}>
      {content.map(({ node }) => (
        <PhilosophyCard
          key={node.frontmatter.title}
          title={node.frontmatter.title}
          description={node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(node.frontmatter.abstract)].node.publicURL
          }
        />
      ))}
    </MobileDeck>
  ) : (
    <DesktopDeck>
      <Tier>
        <PhilosophyCard
          title={content[0].node.frontmatter.title}
          description={content[0].node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(content[0].node.frontmatter.abstract)].node
              .publicURL
          }
        />
        <PhilosophyCard
          title={content[1].node.frontmatter.title}
          description={content[1].node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(content[1].node.frontmatter.abstract)].node
              .publicURL
          }
        />
      </Tier>
      <Tier>
        <PhilosophyCard
          title={content[2].node.frontmatter.title}
          description={content[2].node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(content[2].node.frontmatter.abstract)].node
              .publicURL
          }
        />
        <PhilosophyCard
          title={content[3].node.frontmatter.title}
          description={content[3].node.rawMarkdownBody}
          icon={
            icons[distinct.indexOf(content[3].node.frontmatter.abstract)].node
              .publicURL
          }
        />
      </Tier>
    </DesktopDeck>
  )
}

export default PhilosophyDeck
