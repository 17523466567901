import React from "react"
import "./carousel_steps.css"

const CarouselSteps = props => {
  const { numSteps, currentStep } = props

  const renderSteps = () => {
    let r = []
    for (let i = 0; i < numSteps; i++) {
      r = r.concat(
        <div className="step-wrapper" key={i}>
          <div className={currentStep === i ? "active step" : "step"} />
        </div>
      )
    }
    return r
  }

  return (
    <div className="carousel">
      <div className="steps">{renderSteps()}</div>
    </div>
  )
}

export default CarouselSteps
