import { useState, useEffect } from "react"

function detectTouch() {
  if (typeof window !== `undefined`) {
    return (
      "ontouchstart" in window || navigator.maxTouchPoints // works on most browsers
    ) // works on IE10/11 and Surface
  }
  return false
}

export function useTouchEvents() {
  const [hasTouch, setHasTouch] = useState(detectTouch())

  useEffect(() => {
    setHasTouch(detectTouch())
  }, [])
  return { hasTouch }
}
