import { useState, useEffect } from "react"

var supportsPassive = false
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true
    },
  })
  window.addEventListener("testPassive", null, opts)
  window.removeEventListener("testPassive", null, opts)
} catch (e) {}

function getWindowDimensions() {
  // Wrap the require in check for window
  let width = "100vw"
  let height = "100vh"
  let vh = "1vh"

  if (typeof window !== `undefined`) {
    width = window.innerWidth
    height = window.innerHeight

    // if (width > 1920) {
    //   width = 1920
    // }

    vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener(
      "resize",
      handleResize,
      supportsPassive ? { passive: true } : false
    )
    return () => {
      window.removeEventListener(
        "resize",
        handleResize,
        supportsPassive ? { passive: true } : false
      )
    }
  }, [])

  return windowDimensions
}
