import React, { useState, useEffect } from "react"
import "./philosophy_page.css"
import useWindowDimensions from "../../APIs/window_dimensions"
import { useSwipeable } from "react-swipeable"
import { useTouchEvents } from "../../APIs/hasTouch"

import blob1 from "../../images/PhilosophyPageBlob1.svg"
import blob2 from "../../images/PhilosophyPageBlob2.svg"
import blob3 from "../../images/PhilosophyPageBlob3.svg"
import blob4 from "../../images/PhilosophyPageBlob4.svg"
import person from "../../images/PhilosophyPagePerson.svg"

import backArrow from "../../images/BackArrow.svg"
import forwardArrow from "../../images/ForwardArrow.svg"

import PhilosophyCard from "../../components/PhilosophyCard/PhilosophyCard"
import CarouselSteps from "../../components/CarouselSteps/CarouselSteps"
import PhilosophyDeck from "../../components/PhilosophyCard/PhilosophyDeck"

const BackgroundImages = () => (
  <React.Fragment>
    <img id="blob1" className="blob" src={blob1} alt="" />
    <img id="blob2" className="blob" src={blob2} alt="" />
    <img id="blob3" className="blob" src={blob3} alt="" />
    <img id="blob4" className="blob" src={blob4} alt="" />
    <img id="person" className="blob" src={person} alt="" />
  </React.Fragment>
)

const MobileTransition = () => (
  <div className="mobile-transition">
    <img id="blob3" className="blob" src={blob3} alt="" />
    <img id="person" className="blob" src={person} alt="" />
  </div>
)

const PhilosophyPage = () => {
  const { height, width } = useWindowDimensions()
  const [currentGrid, setCurrentGrid] = useState(0)
  const [isMobile, setIsMobile] = useState(true)
  const { hasTouch } = useTouchEvents()

  useEffect(() => {
    if (height >= 720 && width >= 1280) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [height, width])

  const cardWidth = Math.min(400, 0.86 * width)

  const cardDisplacement = cardWidth * -1 * currentGrid

  const marginLeft = Math.max(16, 0.05 * width)

  const calcTranslateX = cardDisplacement - 12 * currentGrid + marginLeft

  const handleSwipe = dir => {
    let newOrder = currentGrid

    switch (dir) {
      case "NEXT": {
        if (currentGrid !== 3) newOrder = currentGrid + 1
        setCurrentGrid(newOrder)
        break
      }
      case "PREV": {
        if (currentGrid !== 0) newOrder = currentGrid - 1
        setCurrentGrid(newOrder)
        break
      }
      default:
        return null
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe("NEXT")
    },
    onSwipedRight: () => {
      handleSwipe("PREV")
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  })

  return (
    <React.Fragment>
      <MobileTransition />
      <div className="philosophy-page" id="philosophy-page">
        <BackgroundImages />
        <div
          className="background-heading"
          style={{
            fontSize: isMobile
              ? ""
              : 50 +
                (175 - 50) * ((Math.min(1920, width) - 300) / (1600 - 300)),
          }}
        >
          DESIGN
        </div>
        <section>
          <div className="content">
            <p
              className="heading"
              style={{
                marginLeft: isMobile ? marginLeft + 12 : 12,
              }}
            >
              DESIGN
            </p>
            <div className="deck-wrapper">
              {isMobile ? (
                <React.Fragment>
                  <CarouselSteps currentStep={currentGrid} numSteps={4} />
                  {currentGrid !== 0 ? (
                    <div
                      className="prev deck-step"
                      onClick={() => handleSwipe("PREV")}
                      style={{ display: hasTouch ? "none" : "" }}
                    >
                      <img
                        className="arrows"
                        src={backArrow}
                        alt="back-arrow"
                      />
                    </div>
                  ) : null}
                  {currentGrid !== 3 ? (
                    <div
                      className="next deck-step"
                      onClick={() => handleSwipe("NEXT")}
                      style={{ display: hasTouch ? "none" : "" }}
                    >
                      <img
                        className="arrows"
                        src={forwardArrow}
                        alt="forward-arrow"
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              <PhilosophyDeck
                isMobile={isMobile}
                displacement={calcTranslateX}
                handlers={handlers}
              />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default PhilosophyPage
